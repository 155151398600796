import HomePageTopTenLists from './HomePageTenLists';
import { Checkbox, CheckboxGroup } from 'rsuite';


import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { useState } from 'react';

const Main = ({ setQuery }) => {
    const [localQuery, setLocalQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [fixed, setFixed] = useState(true);
    const [auction, setAuction] = useState(true);
    const [asc, setAsc] = useState(true);
    const [desc, setDesc] = useState(false);
    const [comc, setComc] = useState(true);
    const [ebay, setEbay] = useState(true);
    const [myslabs, setMyslabs] = useState(true);
    const [pwcc, setPwcc] = useState(true);
    const [pc, setPc] = useState(true);
    const [fan, setFan] = useState(true);
    const [arena, setArena] = useState(true);
    const [endingSoonest, setEndingSoonest] = useState(false);
    const [newlyCreated, setNewlyCreated] = useState(false);


    const submitSearch = (e) => {
        console.log(`submit search called open is ${open}`)
        e.preventDefault();
        let q = localQuery
        if(open) {
            var sites = getSitesToSearchInternal();
            var sorts = getSorting();
            var types = getType();
            setQuery(localQuery, true, sites, sorts, types);
        } else {
            setQuery(localQuery, false);
        }
   
    }
    const getSitesToSearchInternal = () => {
        const s = [];
        if (comc) {
            s.push('comc')
        }
        if (ebay) {
            s.push('ebay')
        }
        if (myslabs) {
            s.push('myslabs');
        }
        if (pwcc) {
            s.push('pwcc');
        }
        if (fan) {
           s.push('fan');
        }
        if (arena) {
            s.push('arena');
        }
        return s
    }
    const getSorting = () =>{
        if (asc) {
            return 'PriceAsc';
        } else if (desc) {
            return 'PriceDesc';
        } else if (endingSoonest) {
            return 'EndingSoonest';
        } else {
            return 'NewlyCreated';
        }
    }
    const getType = () => {
        if (fixed && auction) {
            return 'ALL'
        } else if (auction) {
            return 'AUCTION';
        } else {
            return 'FIXEDPRICE';
        }
    }
    const getLinkForHighlights = (keywords) => `/?keywords=${encodeURIComponent(keywords)}&a=true`
    //Owl Carousel Settings
    const options = {
        center: true,
        items: 2,
        loop: true,
        margin: 0,
        responsive: {
            0: {
                items: 1
            },
            767: {
                items: 2
            },
            1000: {
                items: 3
            },
            1400: {
                items: 3
            }
        }
    };
    return (<>
        <div className="caro_header">
            <div className="wrapper">
                <div className="container">
                    {/* <h3>Find your next trading card without opening multiple tabs.</h3>
                    <p>All the sites you love in one place.</p> */}
                    <Form className="d-flex" onSubmit={submitSearch}>
                        <Form.Control type="text" placeholder="Player, grade..." size="md" className=" mr-sm-2" value={localQuery} onInput={e => setLocalQuery(e.target.value)} />
                        <Button variant="outline-success" type="submit">Search</Button>
                    </Form>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                    >
                        Advanced Search
                    </Button>
                    <Collapse in={open}>
                        <div className="row">
                        <div className="col-md">
                            <CheckboxGroup>
                                <p>Sites</p>
                                
                                <Checkbox checked={comc} onChange={(a, b, c) => setComc(b)} className="text-gold">Comc</Checkbox>
                                <Checkbox checked={ebay} onChange={(a, b, c) => setEbay(b)} className="text-gold">Ebay</Checkbox>
                                <Checkbox checked={myslabs} onChange={(a, b, c) => setMyslabs(b)} className="text-gold">MySlabs</Checkbox>
                                <Checkbox checked={pwcc} onChange={(a, b, c) => setPwcc(b)} className="text-gold">PWCC</Checkbox>
                                <Checkbox checked={fan} onChange={(a, b, c) => setFan(b)} className="text-gold">Fanatics</Checkbox>
                                <Checkbox checked={arena} onChange={(a, b, c) => setArena(b)} className="text-gold">ArenaClub</Checkbox>
                            </CheckboxGroup>
                        </div>
                        <div className="col-md">
                            <CheckboxGroup>
                                <p>Type</p>
                                <Checkbox checked={auction} onChange={(a, b, c) => setAuction(b)} className="text-gold">Auctions</Checkbox>
                                <Checkbox checked={fixed} onChange={(a, b, c) => setFixed(b)} className="text-gold">FixedPrice</Checkbox>
                            </CheckboxGroup>
                        </div>
                        <div className="col-md">
                            <CheckboxGroup>
                                <p>Sort</p>
                                <Checkbox checked={asc} onChange={(a, b, c) =>  {
                                     setAsc(b)
                                     setDesc(!b)
                                     setEndingSoonest(!b)
                                     setNewlyCreated(!b)
                                     }} className="text-gold">Low to High</Checkbox>
                                <Checkbox checked={desc} onChange={(a, b, c) =>{
                                     setDesc(b)
                                     setAsc(!b)
                                     setEndingSoonest(!b)
                                     setNewlyCreated(!b)
                                }} className="text-gold">High to Low</Checkbox>
                                <Checkbox checked={endingSoonest} onChange={(a, b, c) =>{
                                    setEndingSoonest(b)
                                     setDesc(!b)
                                     setAsc(!b)
                                     setNewlyCreated(!b)
                                }} className="text-gold">Ending Soonest</Checkbox>
                                <Checkbox checked={newlyCreated} onChange={(a, b, c) =>{
                                    setNewlyCreated(b)
                                    setEndingSoonest(!b)
                                     setDesc(!b)
                                     setAsc(!b)
                                }} className="text-gold">Newly Created</Checkbox>
                            </CheckboxGroup>
                        </div>
</div>
                    </Collapse>
                </div>
            </div>
        </div >
        < div className="container container-custom margin_80_0" >
            <div className="main_title_2">
                <span><em></em></span>
                <br />
                <p className="terms2">When you click on links to various merchants on this site and make a purchase, this can result in this site earning a commission. Affiliate programs and affiliations include, but are not limited to, the eBay Partner Network.</p>
                <HomePageTopTenLists />

            </div>
        </div >
    </>
    )

}

export default Main;
