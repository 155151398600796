import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faUser } from '@fortawesome/free-solid-svg-icons';
import { Drawer, Dropdown, Button, Nav, Sidenav, CheckPicker, Divider, Checkbox, InputNumber } from 'rsuite';
import { useState, useEffect } from 'react';
import numeral from 'numeral';
const Metrics = ({ metrics,
                //    setSort,
                //    setType,
                //    setSites,
                   lastQuery,
                //    setPriceMin,
                //    setPriceMax,
                //    setPWCCWeeklies,
                //    setPWCCPremiers,
                   lastBody,
                   runSearch }) => {


    const getNicelyDisplayed = () => {
        const nicelydisplayed = [];
        if(ebay){
            nicelydisplayed.push('Ebay');
        }
        if(comc){
            nicelydisplayed.push('Comc');
        }
        if(myslabs){
            nicelydisplayed.push('MySlabs');
        }
        if(pwcc){
            nicelydisplayed.push('Pwcc');
        }
        if(fan){
            nicelydisplayed.push('Fanatics');
        }
        if(arena){
            nicelydisplayed.push('Arena Club');
        }
    return nicelydisplayed;
    }
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [comc, setComc] = useState(lastBody.datasources.indexOf('comc') !== -1);
    const [ebay, setEbay] = useState(lastBody.datasources.indexOf('ebay') !== -1);
    const [myslabs, setMyslabs] = useState(lastBody.datasources.indexOf('myslabs') !== -1);
    const [pwcc, setPwcc] = useState(lastBody.datasources.indexOf('pwcc') !== -1);
    const [fan, setFan] = useState(lastBody.datasources.indexOf('fan') !== -1);
    const [arena, setArena] = useState(lastBody.datasources.indexOf('arena') !== -1);
    const [sitesToSearch, setSitesToSearch] = useState(getNicelyDisplayed())
    const [fixed, setFixed] = useState(lastBody.type === 'ALL' || lastBody.type === 'FIXEDPRICE');
    const [auction, setAuction] = useState(lastBody.type === 'ALL' || lastBody.type === 'AUCTION');
    const [auctionWeeklies, setAuctionWeeklies] = useState(true);
    const [auctionPremiers, setAuctionPremiers] = useState(true);
    const [asc, setAsc] = useState(lastBody.sort === 'PriceAsc');
    const [desc, setDesc] = useState(lastBody.sort === 'PriceDesc');
    const [endingSoonest, setEndingSoonest] = useState(lastBody.sort === 'EndingSoonest');
    const [newlyCreated, setNewlyCreated] = useState(lastBody.sort === 'NewlyCreated');

    const [priceLow, setPriceLow] = useState(parseInt(lastBody.priceLow,10));
    const [priceHigh, setPriceHigh] = useState(parseInt(lastBody.priceHigh,10));
    const [priceDescription, setPriceDescription] = useState('');


    useEffect(() => {priceFilter()},[priceLow,priceHigh])
 
    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);
    };

    const getSitesToSearch = () => {
        const s = [];
        if (comc) {
            s.push('Comc')
        }
        if (ebay) {
            s.push('Ebay')
        }
        if (myslabs) {
            s.push('MySlabs');
        }
        if (pwcc) {
            s.push('Pwcc');
        }
        if(fan){
            s.push('Fanatics');
        }
        if(arena){
            s.push('Arena Club');
        }
        return s
    }

    const getSitesToSearchInternal = () => {
        const s = [];
        if (comc) {
            s.push('comc')
        }
        if (ebay) {
            s.push('ebay')
        }
        if (myslabs) {
            s.push('myslabs');
        }
        if (pwcc) {
            s.push('pwcc');
        }
        if (fan) {
           s.push('fan');
        }
        if (arena) {
            s.push('arena');
        }
        return s
    }
    const search = () => {
        setOpen(false);
        // set type
        let searchType = '',sortDir = '';
        if (fixed && auction) {
            searchType = 'ALL'
        } else if (auction) {
            searchType ='AUCTION'
        } else {
            searchType ='FIXEDPRICE'
        }
        //set direction sort
        if (asc) {
            sortDir = 'PriceAsc';
        } else if (desc) {
            sortDir = 'PriceDesc';
        } else if (endingSoonest) {
            sortDir = 'EndingSoonest';
        } else {
            sortDir = 'NewlyCreated';
        }

        //set sites
        const s = getSitesToSearchInternal();
        runSearch(lastQuery,true,s, sortDir, searchType, auctionWeeklies, auctionPremiers,priceLow, priceHigh);
 
    }

    const resetSearch = () => {
        setOpen(false);
        setAsc(true);
        setFixed(true);
        setAuction(true);
        setAuctionWeeklies(true);
        setAuctionPremiers(true);
        setAsc(true);
        setDesc(false);
        setEndingSoonest(false);
        setNewlyCreated(false);
        setComc(true);
        setEbay(true);
        setMyslabs(true);
        setPwcc(true)
        setFan(true);
        setArena(true);
        setSitesToSearch(["Ebay", "Comc", "Pwcc", "MySlabs","fan","arena"])
        setPriceLow(0);
        setPriceHigh(0);
        setPriceDescription('');
        //trigger search
        runSearch(lastQuery,false)
    }

    const getType = () => {
        if (fixed && auction) {
            return 'Both Auctions & FixedPrice';
        } else if (auction) {
            return 'Auctions';
        } else {
            return 'FixedPrice/BIN';
        }
    }
    const getSort = () => {
        if (asc) {
            return 'Price Ascending';
        } else if (desc) {
            return 'Price Descending';
        } else if (endingSoonest) {
            return 'Ending Soonest';
        } else {
            return 'Newly Created';
        }
    }

    const handleFixedChange = (a, b, c) => {
        setFixed(b);
    }

    const handleAuctionChange = (a, b, c) => {
        setAuction(b);
    }

    const handleAscChange = (a, b, c) => {
        setAsc(b);
        setDesc(!b);
        setEndingSoonest(!b);
        setNewlyCreated(!b);
    }
    const handleDescChange = (a, b, c) => {
        setDesc(b);
        setAsc(!b);
        setEndingSoonest(!b);
        setNewlyCreated(!b);
    }
    const handleEndingSoonestChange = (a, b, c) => {
        setDesc(!b)
        setAsc(!b)
        setNewlyCreated(!b);
        setEndingSoonest(b);
    }

    const handleNewlyCreatedChange = (a, b, c) => {
        setDesc(!b)
        setAsc(!b)
        setEndingSoonest(!b);
        setNewlyCreated(b);
    }

    const handleComcChange = (a, b, c) => {
        setComc(b);
        // getSitesToSearch();
    }
    const handleEbayChange = (a, b, c) => {
        setEbay(b);
        // getSitesToSearch();
    }
    const handleMyslabsChange = (a, b, c) => {
        setMyslabs(b);
        // getSitesToSearch();
    }
    const handlePwccChange = (a, b, c) => {
        setPwcc(b);
        // getSitesToSearch();
    }

    const handleFanChange = (a,b,c) =>{
        setFan(b);
    }

    const handleArenaChange = (a,b,c) => {
        setArena(b);
    }

    const handleAuctionWeekliesChange = (a, b,c) => {
        setAuctionWeeklies(b);
    }

    const handleAuctionPremiersChange = (a, b,c) => {
        setAuctionPremiers(b);
    }

    const priceFilter = () => {
        if (priceLow && priceHigh && priceLow > 0 && priceHigh > 0) {
            setPriceDescription(`$${numeral(priceLow).format('0,0')} - $${numeral(priceHigh).format('0,0')}`)
        } else if (priceLow && priceLow > 0) {
            setPriceDescription(`From $${numeral(priceLow).format('0,0')}`)
        } else if (priceHigh && priceHigh > 0) {
            setPriceDescription(`Under $${numeral(priceHigh).format('0,0')}`)
        } else {
            setPriceDescription('');
        }
    }

    const getPriceExplanation = () => {
      return  priceDescription.length === 0 ? 'Any': priceDescription;
    }

    return (
        <>
            <div className="filters_listing stickymetrics ">
                <div className="container">
                    <ul className="clearfix">
                        <li><div className="layout_view"><div className="text-gold-inline">Low: {metrics.low} </div> | <div className="text-gold-inline">High: {metrics.high}</div> | <div className="text-gold-inline">Average: {metrics.average}</div></div></li>
                        <li><div className="layout_view"><div className="text-gold-inline">Filter<FontAwesomeIcon icon={faFilter} onClick={e => handleOpen('left')} /></div></div></li>
                        <li><div className="layout_view d-none d-md-block">
                             | <div className="text-gold-inline">Comc: {metrics.counts.comc} </div>
                             | <div className="text-gold-inline">Ebay: {metrics.counts.ebay}</div>
                             | <div className="text-gold-inline">myslabs: {metrics.counts.mys}</div>
                             | <div className="text-gold-inline"> pwcc: {metrics.counts.pwcc} </div>
                             | <div className="text-gold-inline"> fan: {metrics.counts.fan} </div>
                             | <div className="text-gold-inline"> arena: {metrics.counts.arena} </div>
                             </div></li>
                    </ul>
                </div>
            </div>
            <Drawer placement={placement} size={'xs'} open={open} onClose={() => setOpen(false)} className="rs-theme-dark">
                <Drawer.Header style={{ backgroundColor: '#121921' }}>
                    <Drawer.Title style={{ color: '#edc773' }}>Filter & Sort</Drawer.Title>
                    <Drawer.Actions>
                        {/* <Button onClick={() => setOpen(false)}>Close</Button> */}
                        <Button color="yellow" appearance="primary" onClick={() => search()}>Apply</Button>
                        <Button color="red" appearance="primary" onClick={() => resetSearch()}>Reset</Button>
                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body style={{ backgroundColor: '#121921' }}>
                    <Divider className="text-gold">Type</Divider>
                    <Checkbox checked={fixed} onChange={handleFixedChange} className="text-gold">FixedPrice/BIN</Checkbox>
                    <Checkbox checked={auction} onChange={handleAuctionChange} className="text-gold">Auction</Checkbox>
                    
                    <br/>
                    { auction && pwcc ? <Checkbox checked={auctionWeeklies} onChange={handleAuctionWeekliesChange} className="text-gold">Include PWCC Weekly Auctions</Checkbox> : null}
                    { auction && pwcc ? <Checkbox checked={auctionPremiers} onChange={handleAuctionPremiersChange} className="text-gold">Include PWCC Premier Auctions</Checkbox> : null}

                    <Divider className="text-gold">Sort</Divider>
                    <Checkbox checked={asc} onChange={handleAscChange} className="text-gold">Low to High</Checkbox>
                    <Checkbox checked={desc} onChange={handleDescChange} className="text-gold">High to Low</Checkbox>
                    <Checkbox checked={endingSoonest} onChange={handleEndingSoonestChange} className="text-gold">Ending Soonest</Checkbox>
                    <Checkbox checked={newlyCreated} onChange={handleNewlyCreatedChange} className="text-gold">Newly Created</Checkbox>
                    <Divider className="text-gold">Sites</Divider>
                    <Checkbox checked={comc} onChange={handleComcChange} className="text-gold">Comc</Checkbox>
                    <Checkbox checked={ebay} onChange={handleEbayChange} className="text-gold">Ebay</Checkbox>
                    <Checkbox checked={myslabs} onChange={handleMyslabsChange} className="text-gold">MySlabs</Checkbox>
                    <Checkbox checked={pwcc} onChange={handlePwccChange} className="text-gold">PWCC</Checkbox>
                    <Checkbox checked={fan} onChange={handleFanChange}  className="text-gold">Fanatics</Checkbox>
                    <Checkbox checked={arena} onChange={handleArenaChange} className="text-gold">ArenaClub</Checkbox>
                    <Divider className="text-gold">Price</Divider>
                    <span className="filterExplainTitle">{priceDescription}</span><p></p>
                    From:
                    <InputNumber prefix="$" step={1} value={priceLow} onChange={setPriceLow} min={0}/>
                    To:
                    <InputNumber prefix="$" step={1} value={priceHigh} onChange={setPriceHigh} min={0}/>
                    <Divider></Divider>
                    <p><span className="filterExplainTitle">Searching Sites:</span><span className="text-gold"> {getSitesToSearch().join(', ')}</span></p>
                    <p><span className="filterExplainTitle">For:</span><span className="text-gold"> {getType()}</span></p>
                    <p><span className="filterExplainTitle">Sorting:</span><span className="text-gold"> {getSort()}</span></p>
                    <p><span className="filterExplainTitle">For Search Term:</span><span className="text-gold"> {lastQuery}</span></p>
                    <p><span className="filterExplainTitle">Pricing:</span><span className="text-gold"> {getPriceExplanation()}</span></p>
                </Drawer.Body>
            </Drawer>
        </>
    )
}

export default Metrics;