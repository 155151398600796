import { DateTime } from "luxon";
import noimage from './noimage.png';
const Card = (item, key) => {
    const type = (item.data && item.data.type) ? item.data.type : 'FixedPrice';
    const isAuctionSite = ['EBAY', 'PWCC', 'COMC'].includes(item.data.src.toUpperCase());
    const showAuctionText = type.toUpperCase() === 'Auction'.toUpperCase();
    let label = '';
    if (showAuctionText && item.data && item.data.timeLeft) {

        const { days, hours,minutes, endDate } = item.data.timeLeft;
        if (days === 0) {
            if(hours === 0) {
            label = `${minutes}m`
            } else {
                label = `${hours}h`
            }

        } else {
            if (item.data.src.toUpperCase() === 'COMC' && (hours === 0)) {
                label = `${days}d`
            } else {
                label = `${days}d ${hours}h`
            }
        }
        if (item.data.src.toUpperCase() === 'PWCC') {
            if (endDate && endDate !== null && endDate !== undefined) {
                const parts = endDate.split("-");
                const end = DateTime.fromObject({ year: parts[0], month: parts[1], day: parts[2], hour: 19, minute: 0 }, { zone: 'America/Los_Angeles' })
                const now = DateTime.now();
                const diff = end.diff(now, ["days", "hours", "minutes"])
                const almost = diff.toObject();
                const x = {
                    days: almost.days < 0 ? 0 : almost.days,
                    hours: Math.floor(almost.hours) < 0 ? 0 : Math.floor(almost.hours),
                    minutes: parseInt(almost.minutes) < 0 ? 0 : parseInt(almost.minutes)
                }
                if (x.days === 0 && x.hours === 0 && Math.round(x.minutes) === 0) {
                    label = '';
                } else {
                    if (x.days === 0) {
                        if (x.hours === 0) {
                            label = `${x.minutes}m`;
                        } else {
                            label = `${x.hours}h ${x.minutes}m`
                        }
                    } else {
                        label = `${x.days}d ${x.hours}h`
                    }
                }

            } else {
                label = '';
            }
        }
    }


            return (
                <div className="col-xl-4 col-lg-6 col-md-6">
                    <div className="box_grid">
                        <figure>
                            <a href={item.data.link} target="_blank" rel="noopener">
                                {item.data.src && item.data.src.toUpperCase() === 'GN'  && item.data.img.length === 0 ?  <img src={noimage} loading="lazy" className="img-fluid" />:
                                <img src={item.data.img} loading="lazy" className="img-fluid" />}
                            </a>
                            <small className={showAuctionText ? 'auction' : ''}>{item.data.src}{(isAuctionSite && showAuctionText) ? <div>Auction</div> : <div>BIN</div>}</small>
                        </figure>
                        <div className="wrapper">
                            <p>{item.data.title}</p>
                            <span className="price"><strong>{item.data.price}</strong ></span>
                            {showAuctionText ? <span className="time"><strong>{label}</strong ></span> : ''}
                            {item.data.src && item.data.src.toUpperCase() === 'COMC' ? ' | COMC members can see slightly lower prices directly on their site.' : null}
                        </div>

                    </div>
                </div>
            )

        }

        export default Card;