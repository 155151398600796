import SiteHeader from './SiteHeader';
import Footer from '../Footer';
import AboutImage from './Brands922.png';
const About = () => {
    return (
        <>
            <SiteHeader />
            <div className="bg_color_1">
                <div className="bg_color_1">
                    <div className="container margin_80_55">
                        <div className="main_title_2">
                            <span><em></em></span>
                            <h2>Our Story</h2>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-lg-6">
                                <figure className="block-reveal">
                                    <div className="block-horizzontal"></div>
                                    <img src={AboutImage} className="img-fluid" />
                                </figure>
                            </div>
                            <div className="col-lg-5">
                                <p>Hobby Exchange is the most comprehensive trading card search engine for physical and digital collectibles. We help you search across the top platforms all at once to find the best prices and most variety.</p>
                                <p>We work with 5 leading platforms:</p>
                                <ol className="aboutus">
                                    <li className="aboutusli">eBay</li>
                                    <li className="aboutusli">PWCC</li>
                                    <li className="aboutusli">COMC</li>
                                    <li className="aboutusli">MySlabs</li>
                                </ol>
                                <p>Our deep partnerships with these platforms allow you to search both Buy It Now listings and live auctions. Additionally, we help you discover more by highlighting trending auctions on our homepage.</p>
                                <p>And now, with our saved search feature, it’s even easier to keep track of your most frequent searches! Give it a try today by clicking on My Account in the menu.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}
export default About;